import React, { FC, useMemo, useState, useCallback } from 'react';
import { PageProps, graphql } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import styles from './styles.module.scss';
import { t } from '../../i18n';
import { YellowHeader } from '../../components/main/YellowHeader';
import { VacancyModal } from '../../components/vacancies/VacancyModal';
import { VacancySuccessModal } from '../../components/vacancies/VacancySuccessModal';
import { IPositionsQuery } from '../../queries/positions/types';
import { IHeaderQuery } from '../../queries/headers/types';
import { VacancyCard } from '../../components/vacancies/VacancyCard';
import { VacancyFreeForm } from '../../components/vacancies/VacancyFreeForm';
import { changePath } from '../../utils/hooks';
import DATA from '../../i18n/locales/en';

type IProps = PageProps<IPositionsQuery & IHeaderQuery, { locale: string }>;

const VacanciesPage: FC<IProps> = ({
  data,
  pageContext: { locale = 'en' },
}) => {
  const [isModalShown, setModalShown] = useState(false);
  const [isSuccessShown, setSuccessShown] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const positions = useMemo(
    () => data.positions.edges.map((edge) => edge.node),
    [data.positions]
  );

  const header = useMemo(
    () => data.headers.edges.find((edge) => edge.node),
    [data.headers]
  );

  const showModal = useCallback(
    (index: number) => () => {
      setActiveItem(index);
      setModalShown(true);
    },
    [setActiveItem, setModalShown]
  );

  const hideModal = useCallback(() => {
    setModalShown(false);
  }, [setModalShown]);

  const showSuccess = useCallback(
    () => setSuccessShown(true),
    [setSuccessShown]
  );
  const hideSuccess = useCallback(
    () => setSuccessShown(false),
    [setSuccessShown]
  );

  const contacts = useMemo(
    () => header?.node.frontmatter.contacts || {},
    [header]
  );

  if (!positions.length) {
    return (
      <MainLayout
        locale={locale}
        title={DATA.vacancies.seo.title}
        description={DATA.vacancies.seo.description}
        keywords={DATA.vacancies.seo.keywords}
      >
        <YellowHeader header={t('vacancies.title')}>
          {header?.node?.html || ''}
        </YellowHeader>

        <VacancyFreeForm />
      </MainLayout>
    );
  }

  return (
    <MainLayout
      locale={locale}
      title={DATA.vacancies.seo.title}
      description={DATA.vacancies.seo.description}
      keywords={DATA.vacancies.seo.keywords}
    >
      <YellowHeader header={t('vacancies.title')}>
        {header?.node?.html || ''}
      </YellowHeader>

      {isModalShown && (
        <VacancyModal
          onClose={hideModal}
          showSuccess={showSuccess}
          position={positions[activeItem]}
          contacts={contacts}
        />
      )}

      {isSuccessShown && <VacancySuccessModal onClose={hideSuccess} />}

      <div className={classnames('content', styles.wrap)}>
        <div className={styles.grid}>
          {positions.map(
            (
              {
                id,
                frontmatter: { type, quality, title, business },
                fileAbsolutePath,
                html,
              },
              index
            ) => (
              <VacancyCard
                index={index}
                showModal={showModal}
                type={type}
                quality={quality}
                description={html}
                title={title}
                business={business}
                slug={changePath(fileAbsolutePath)}
                key={id}
              />
            )
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  {
    positions: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/vacancies/positions/" } }
    ) {
      ...PositionsFields
    }

    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/vacancies/header/header/" } }
    ) {
      ...HeaderFields
    }
  }
`;

export default VacanciesPage;
