import React, {
  FC,
  useCallback,
  HTMLAttributes,
  useState,
  ChangeEvent,
} from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../i18n';

interface IProps extends HTMLAttributes<HTMLTextAreaElement> {
  value: string;
  name: string;
  hasError?: boolean;
  error?: string;
  handler: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  removeError?: (
    fieldName: string,
    prevValue: string,
    curValue: string
  ) => void;
}

const Textarea: FC<IProps> = ({
  value,
  label,
  handler,
  hasError,
  error,
  placeholder,
  name,
  removeError,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const { messages } = useIntl();

  const onFocus = useCallback(() => setFocused(true), [setFocused]);
  const onBlur = useCallback(() => setFocused(false), [setFocused]);
  const onChange = useCallback(
    (event) => {
      if (removeError) {
        removeError(name, value, event.target.value);
      }
      return handler(event);
    },
    [handler]
  );
  return (
    <div
      className={classNames(styles.wrap, {
        [styles.has_error]: hasError || error,
      })}
    >
      {label && (
        <div
          className={classNames(styles.label, {
            [styles.focused]: focused || !!value,
          })}
        >
          {t(label)}
        </div>
      )}

      <div className={styles.input__error}>
        {(error && t(error)) || (hasError && t('contacts.fill_this_field'))}
      </div>

      <div className={styles.textarea__block}>
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          placeholder={(placeholder && (messages[placeholder] as string)) || ''}
          onFocus={onFocus}
          onBlur={onBlur}
          {...props}
        />
      </div>
    </div>
  );
};

export { Textarea };
